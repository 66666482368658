<template>
  <div class="upgradeList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>拼团活动升级列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button
      class="btn"
      type="primary"
      @click="onAdd()"
      v-if="$store.state.powerList.indexOf('groupBuy:upgrade:list:add') !== -1"
      >添加</el-button
    >
    <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        align="center"
        prop="upgradeId"
        label="升级ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="oldProdName"
        label="活动产品"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="price"
        label="活动价格"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="usrTime"
        label="使用时间"
        width="300"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="prodName"
        label="升级产品"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="width">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="onDelRow(row.upgradeId)"
            v-if="
              $store.state.powerList.indexOf('groupBuy:upgrade:list:delete') !==
              -1
            "
            >删除</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/upgradePrice/${row.upgradeId}`)"
            >设置差价</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog title="添加升级套餐" :visible.sync="dialogVisible" width="30%">
      <div v-if="msg == '1'">
        <el-table
          class="table1"
          size="mini"
          border
          :data="addUpgradeData"
          @selection-change="onSelectionChange1"
          style="width: 100%"
        >
          <el-table-column
            align="center"
            type="selection"
            width="width"
          ></el-table-column>
          <el-table-column align="center" prop="prodId" label="客房ID">
          </el-table-column>
          <el-table-column align="center" prop="prodName" label="客房名称">
          </el-table-column>
          <el-table-column align="center" prop="fatherName" label="酒店名称">
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row }">
              <el-button
                type="primary"
                size="mini"
                @click="onAddRow(row.prodId)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" size="mini" class="btn1" @click="onAddAll"
          >批量添加</el-button
        >
      </div>
      <div v-if="msg == '2'">
        <el-table
          class="table1"
          size="mini"
          border
          @selection-change="onSelectionChange1"
          :data="addUpgradeData"
          style="width: 100%"
        >
          <el-table-column
            align="center"
            type="selection"
            width="width"
          ></el-table-column>
          <el-table-column align="center" prop="prodId" label="套餐ID">
          </el-table-column>
          <el-table-column align="center" prop="prodName" label="套餐名称">
          </el-table-column>
          <el-table-column align="center" prop="fatherName" label="线路名称">
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row }">
              <el-button
                type="primary"
                size="mini"
                @click="onAddRow(row.prodId)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" size="mini" class="btn1" @click="onAddAll">
          批量添加</el-button
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  upgradeList,
  delUpgrade,
  selectProdInfo,
  addUpgrade,
} from "../../../api/groupBuy";
export default {
  name: "upgradeList",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      msg: "",
      tableData: [],
      pagination: {},
      addUpgradeData: [],
      ids1: [],
      list: {
        currentPage: 1,
        pageSize: 5,
        groupBuyId: Number(this.id),
      },
    };
  },
  created() {
    this.getupgradeList();
  },
  methods: {
    async onAddRow(prodId) {
      const { data } = await addUpgrade({
        groupBuyId: Number(this.id),
        prodIds: prodId + "",
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.getupgradeList();
        this.dialogVisible = false;
      } else {
        this.$message.error(data.msg);
      }
    },
    onSelectionChange1(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.prodId);
      });
      this.ids1 = arr;
    },
    async onAddAll() {
      if (this.ids1.length == 0) {
        return this.$message.error("请选择要添加的数据");
      }
      const { data } = await addUpgrade({
        groupBuyId: Number(this.id),
        prodIds: this.ids1.join(","),
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.getupgradeList();
        this.dialogVisible = false;
        this.ids1 = [];
      } else {
        this.$message.error(data.msg);
      }
    },
    onAdd() {
      this.dialogVisible = true;
      this.getselectProdInfo();
    },
    async getselectProdInfo() {
      const { data } = await selectProdInfo({
        groupBuyId: Number(this.id),
      });
      console.log(data);
      this.addUpgradeData = data.data;
      this.msg = data.msg;
    },
    onDelRow(upgradeId) {
      this.$confirm("是否删除这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delUpgrade({
            upgradeId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getupgradeList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async getupgradeList() {
      const { data } = await upgradeList(this.list);
      console.log(data);
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getupgradeList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getupgradeList();
    },
  },
};
</script>
<style lang="less" scoped>
.upgradeList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .btn {
    margin-top: 50px;
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .el-dialog {
    .table1 {
      margin-top: 5px;
    }
    .btn1 {
      margin-top: 10px;
    }
  }
}
</style>