var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upgradeList"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("拼团活动升级列表")])],1),(_vm.$store.state.powerList.indexOf('groupBuy:upgrade:list:add') !== -1)?_c('el-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onAdd()}}},[_vm._v("添加")]):_vm._e(),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"align":"center","prop":"upgradeId","label":"升级ID"}}),_c('el-table-column',{attrs:{"align":"center","prop":"oldProdName","label":"活动产品"}}),_c('el-table-column',{attrs:{"align":"center","prop":"price","label":"活动价格"}}),_c('el-table-column',{attrs:{"align":"center","prop":"usrTime","label":"使用时间","width":"300"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodName","label":"升级产品"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(
            _vm.$store.state.powerList.indexOf('groupBuy:upgrade:list:delete') !==
            -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onDelRow(row.upgradeId)}}},[_vm._v("删除")]):_vm._e(),_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.$router.push(("/upgradePrice/" + (row.upgradeId)))}}},[_vm._v("设置差价")])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.list.currentPage,"page-sizes":[5, 10, 15, 20],"page-size":_vm.list.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pagination.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('el-dialog',{attrs:{"title":"添加升级套餐","visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.msg == '1')?_c('div',[_c('el-table',{staticClass:"table1",staticStyle:{"width":"100%"},attrs:{"size":"mini","border":"","data":_vm.addUpgradeData},on:{"selection-change":_vm.onSelectionChange1}},[_c('el-table-column',{attrs:{"align":"center","type":"selection","width":"width"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodId","label":"客房ID"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodName","label":"客房名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"fatherName","label":"酒店名称"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onAddRow(row.prodId)}}},[_vm._v("添加")])]}}],null,false,2686543785)})],1),_c('el-button',{staticClass:"btn1",attrs:{"type":"primary","size":"mini"},on:{"click":_vm.onAddAll}},[_vm._v("批量添加")])],1):_vm._e(),(_vm.msg == '2')?_c('div',[_c('el-table',{staticClass:"table1",staticStyle:{"width":"100%"},attrs:{"size":"mini","border":"","data":_vm.addUpgradeData},on:{"selection-change":_vm.onSelectionChange1}},[_c('el-table-column',{attrs:{"align":"center","type":"selection","width":"width"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodId","label":"套餐ID"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodName","label":"套餐名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"fatherName","label":"线路名称"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onAddRow(row.prodId)}}},[_vm._v("添加")])]}}],null,false,2686543785)})],1),_c('el-button',{staticClass:"btn1",attrs:{"type":"primary","size":"mini"},on:{"click":_vm.onAddAll}},[_vm._v(" 批量添加")])],1):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }